* {
  margin: 0px;
  padding: 0px;
  font-family: "Libre Franklin";
}
.main22-landing {
  width: 100%;
}
.mainDevelop-landing {
  height: 100%;
}
.landing {
  max-width: 1280px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 30;
}
.leftDevelop-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-text-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.RightDevelop-landing {
  text-align: end;
  justify-content: end;
}
.subLeftDevelop1-landing h1 {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.landing-text-para {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.servicesDevelop-landing {
  font-family: "Libre Franklin";
  font-style: normal;
  background: linear-gradient(185deg, #fff 25%, #a562f7 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 60px;
}
.partners-bgrnd {
  align-content: center;
  align-items: center;
  background-color: #150621;
  display: flex;
  justify-content: center;
  
}
.ourpartners-landing-text {
  background: #a562f7;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-align: center; */
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 200;
  line-height: 22px;
 
}
.partners-container {
  /* width: 100%;
  overflow: hidden;
  white-space: nowrap; */
  position: relative;
}
/* .partner-logo {
  display: inline-block;
  animation: scrollRight 20s linear infinite;

} */

.partners-layer {
  background: linear-gradient(
    91deg,
    rgb(19, 7, 32) 0.03%,
    #00000000 32.74%,
    #00000000 67.26%,
    rgb(19, 7, 32) 100%
  );
}

.landing-btn-main {
  display: flex;
  padding: 16px 40px;
  border-radius: 16px;
  background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  color: #fff;
  font-family: "Libre Franklin";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 8%;
  cursor: pointer;
  text-align: left;
}
.landing-btn-main:hover {
  background: linear-gradient(180deg, #a562f7 0%, #3b4e9a 100%);
  transform: scale(1.1);
}
.myVideo {
  right: 0;
  bottom: 0;
  min-width: 100%;
  /* min-height: 100%; */
  width: 100%;
  /* height: 90vh; */
  object-fit: cover;
}
.translation-text {
  transform: translateY(0px);
  animation: text-landing-rotate 10s  ease-in-out infinite ;
}

@keyframes text-landing-rotate {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-70px);
  }
  40% {
    transform: translateY(-140px);
  }
  60% {
    transform: translateY(-210px);
  }
  80% {
    transform: translateY(-280px);
  }
  100% {
    transform: translateY(-350px);
  }
}

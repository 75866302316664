* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.main22-infra {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @apply px-6 pb-6 pt-10 lg:px-0 lg:py-20 mt-10 lg:mt-20 ;
}
.mainDevelop-infra {
  margin: auto;
  display: flex;
}
.RightDevelop-infra {
  width: 55%;
}
.RightDevelop-infra p {
  text-align: start;
  color: rgb(225, 225, 225);
  margin-left: 18px;
}
.subLeftDevelop1-infra h1 {
  color: rgb(225, 225, 225);
  font-weight: 600;
  font-family: "Libre Franklin";
  margin-bottom: 1%;
}
.alert2001-infra {
  width: 100%;
  padding: 30px;
  text-align: start;
  border-radius: 16px;
}
.alert2001-infra:hover {
  background: #372752;
}
.servicesDevelop-infra {
  background: linear-gradient(169deg, #6458e7 26.53%, #9760f4 55.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Libre Franklin";
}
.dot1-infra {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  z-index: 1;
  transition: transform 0.8s ease;
}

.dot1-infra:hover {
  transform: scale(1.2);
  z-index: 9999;
}
.dot1-infra-gradient {
  background: radial-gradient(circle at 100px 100px, #f07576, #000);
  animation: rotateRadialBg 8s linear infinite;
}

.dot2-infra,
.dot3-infra,
.dot4-infra {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: transform 0.8s ease;
  color: #fff;
}

.dot2-infra {
  z-index: 2;
}
.dot2-infra-gradient {
  background: radial-gradient(circle at 100px 100px, #9474f6, #000);
  animation: rotateRadialBg 8s linear infinite;
}

.dot2-infra:hover {
  transform: scale(1.2);
  z-index: 999;
}

.dot3-infra {
  z-index: 3;
}
.dot3-infra-gradient {
  background: radial-gradient(circle at 100px 100px, #84e0c2, #000);
  animation: rotateRadialBg 8s linear infinite;
}

.dot3-infra:hover {
  transform: scale(1.2);
  z-index: 999;
}

.dot4-infra {
  z-index: 4;
}

.dot4-infra-gradient {
  background: radial-gradient(circle at 100px 100px, #e0b0ff, #000);
  animation: rotateRadialBg 8s linear infinite;
}

@keyframes rotateRadialBg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dot4-infra:hover {
  transform: scale(1.2);
  z-index: 999;
}

.dot5-infra {
  height: 132px;
  width: 132px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 55%;
  margin-top: 45%;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background: linear-gradient(90deg, #e0b0ff 0%, #9474f6 76.04%);
}
.dot6-infra {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(
    circle at 100px 100px,
    rgba(148, 116, 246, 0.4),
    #000
  );
}
.dot7-infra {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 15%;
  background: radial-gradient(
    circle at 100px 100px,
    rgba(224, 176, 255, 0.2),
    #000
  );
}
.dot8-infra {
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  left: 72%;
  background: linear-gradient(
    90deg,
    rgba(224, 176, 255, 0.2) 0%,
    rgba(148, 116, 246, 0.2) 76.04%
  );
}
.dot9-infra {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(
    circle at 100px 100px,
    rgba(132, 224, 194, 0.8),
    #000
  );
}
.dot10-infra {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(
    circle at 100px 100px,
    rgba(240, 117, 118, 0.6),
    #000
  );
}
.figma2001-infra {
  margin-right: 20%;
  width: 100%;
}
.development-alert-head {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.development-alert-text {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.6px;
}
.text-stats {
  color: rgb(225, 225, 225);
  text-align: center;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}
.count-text {
  color: rgb(225, 225, 225);
  text-align: center;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
}
.dot-animation {
  animation: scaleAndOpacityChange 5s ease-in-out infinite;
}

@keyframes scaleAndOpacityChange {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.6;
  }
}

.dot1-infra,
.dot2-infra,
.dot3-infra,
.dot4-infra {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.dot1-infra {
  animation-delay: 0s;
}

.dot2-infra {
  animation-delay: 1s;
}

.dot3-infra {
  animation-delay: 2s;
}

.dot4-infra {
  animation-delay: 3s;
}

.letsTalk-main {
  align-items: center;
  width: 100%;
  @apply px-6 pb-6 pt-6 lg:px-0 lg:py-28;
}

.letsTalk-para-text {
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: rgb(225,225,225);
  letter-spacing: 0.6px;
  font-family: "Libre Franklin";
}

.talk-text {
  background: linear-gradient(169deg, #6458e7 26.53%, #9760f4 55.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Libre Franklin";

  font-style: normal;
  font-weight: 700;
}
.lets-text {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  /* font-size: 54px; */
  font-style: normal;
  font-weight: 700;
  /* line-height: 64px;  */
  /* margin-bottom: 3%; */
}
.right-letsTalk {
  margin-bottom: -5%;
  width: 50%;
}
.form-bg {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.04);
}
.name-form {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  height: 54px;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}
.send-btn {
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  color: rgb(225,225,225);
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.6px;
}
.text-field-bg {
  border: 0.25px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 0px 10px;
}
.left-letsTalk {
  width: 50%;
}
.contact {
  color: rgb(225,225,225);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 70.588% */
  justify-content: center;
}
.v-line {
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.main22-ourPartners {
  width: 100%;
  @apply flex justify-center px-6 pb-6 pt-10 lg:px-0 lg:pt-20 lg:pb-40;
}
.mainDevelop-partners {
  @apply w-full flex flex-col-reverse lg:flex-row;
}
.leftDevelop-partners {
  @apply flex-1;
}
.RightDevelop-partners {
  @apply flex-1;
}
.RightDevelop-partners p {
  text-align: start;
  color: rgb(225, 225, 225);
}
.subLeftDevelop1-partners h1 {
  color: rgb(225, 225, 225);
  font-weight: 600;
  font-family: "Libre Franklin";
}
.alert-main {
  display: flex;
  margin-top: 5%;
}

/* .alert2001 {
  text-align: start;
  border-radius: 16px;
  background: linear-gradient(
    118deg,
    rgba(255, 255, 255, 0.06) 0.03%,
    rgba(255, 255, 255, 0) 100.03%
  );
  margin-bottom: 8%;
} */
.alert2002 {
  text-align: start;
  border-radius: 16px;
  background: linear-gradient(
    126deg,
    rgba(255, 255, 255, 0.06) 0.07%,
    rgba(255, 255, 255, 0) 100.07%
  );
  border: none;
}
.alert2003 {
  border-radius: 16px 0px 0px 16px;
  background: linear-gradient(
    118deg,
    rgba(255, 255, 255, 0.06) -0.06%,
    rgba(255, 255, 255, 0) 99.94%
  );
  text-align: start;
  border: none;
  margin-bottom: 8%;
}
.alert2004 {
  /* background: rgba(255, 255, 255, 0.08); */
  /* width: 70%; */
  text-align: start;
  border-radius: 16px 0px 0px 16px;
  border: none;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.06) 1.87%,
    rgba(255, 255, 255, 0) 99.98%
  );
}
.servicesDevelop {
  background: linear-gradient(169deg, #6458e7 26.53%, #9760f4 55.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Libre Franklin";
}
.dot1 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 21%;
  transform: translateY(100%);
  color: rgb(225, 225, 225);
  font-weight: 600;
  font-size: 18px;
  z-index: 1;
  transition: transform 0.8s ease;
}
.dot1:hover {
  transform: scale(1.2) translateY(80%);
  z-index: 9999;
}
.dot1-gradient {
  background: radial-gradient(circle at 100px 100px, #f07576, #000);
  animation: rotateRadialBg 8s linear infinite;
}

.dot2 {
  /* height: 210px;
  width: 210px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* left: 50%; */
  transform: translatey(-30%);
  color: rgb(225, 225, 225);
  font-weight: 600;
  font-size: 18px;
  z-index: 1;
  transition: transform 0.8s ease;
}
.dot2:hover {
  transform: scale(1.2) translatey(-30%);
  z-index: 999;
}
.dot2-gradient {
  background: radial-gradient(circle at 100px 100px, #9474f6, #000);
  animation: rotateRadialBg 8s linear infinite;
}
.dot3 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateY(-120%);
  color: rgb(225, 225, 225);
  font-weight: 600;
  font-size: 18px;
  z-index: 1;
  transition: transform 0.8s ease;
}
.dot3:hover {
  transform: scale(1.2) translateY(-90%);
  z-index: 999;
}
.dot3-gradient {
  background: radial-gradient(circle at 100px 100px, #84e0c2, #000);
  animation: rotateRadialBg 8s linear infinite;
}
.dot4 {
  border-radius: 50%;
  display: flex;
  position: relative;
  color: rgb(225, 225, 225);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  /* background: #e0b0ff; */
  padding: 2px;
  z-index: 4;
  transition: transform 0.8s ease;
}
.dot4:hover {
  z-index: 999;
}
.dot4-gradient {
  background: radial-gradient(circle at 100px 100px, #e0b0ff, #000);
  animation: rotateRadialBg 8s linear infinite;
}
.dot5 {
  border-radius: 50%;
  color: rgb(225, 225, 225);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  /* background: #d59bb7; */
  position: relative;
  transform: translateY(-319%);
  z-index: 5;
  transition: transform 0.8s ease;
  /* background: radial-gradient(circle at 100px 100px, #d59bb7, #000); */
}
.dot5:hover {
  transform: scale(1.2) translateY(-260%);
  z-index: 999;
}
.dot5-gradient {
  background: radial-gradient(circle at 100px 100px, #d59bb7, #000);
  animation: rotateRadialBg 8s linear infinite;
}
.dot6 {
  /* height: 58px;
  width: 58px; */
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* transform: translateY(-95vh); */
  z-index: 1;
  transition: transform 0.8s ease;
  background: radial-gradient(
    circle at right bottom,
    rgba(148, 116, 246, 0.08),
    #000
  );
}
.dot7 {
  /* height: 41px;
  width: 41px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  left: 52%;
  /* transform: translateY(-56vh); */
  /* margin-top: 56%; */
  /* background: rgba(224, 176, 255, 0.2); */
  background: radial-gradient(circle at 100px 100px, rgba(224, 176, 255), #000);
}
.dot8 {
  height: 16px;
  width: 16px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* background-color: rgba(251, 250, 250, 0.04); */
  border-radius: 50%;
  position: relative;
  left: 72%;
  margin-top: -10%;
  background: linear-gradient(
    90deg,
    rgba(224, 176, 255, 0.2) 0%,
    rgba(148, 116, 246, 0.2) 76.04%
  );
}
.dot9 {
  /* height: 58px;
  width: 58px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* background-color: rgba(251, 250, 250, 0.04); */
  border-radius: 50%;
  position: relative;
  /* left: 14%; */
  /* transform: translateY(-65vh); */
  /* margin-top: -13%; */
  /* background: rgba(132, 224, 194, 0.8); */
  background: radial-gradient(circle at 100px 100px, rgba(132, 224, 194), #000);
}
.dot10 {
  /* height: 58px;
  width: 58px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  /* left: 52%; */
  /* margin-top: -12%; */
  /* transform: translateY(-1516%); */
  /* background: rgba(240, 117, 118, 0.6); */
  background: radial-gradient(circle at 100px 100px, rgba(240, 117, 118), #000);
}
.alert-right1 {
  margin-top: 15%;
  /* margin-left: 5%; */
}
.eng-dot {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #e0b0ff;
}
.eng-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ourPartners-text {
  font-family: "Libre Franklin";
  /* font-size: 14px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.6px;
  text-align: left;
  color: rgb(225, 225, 225);
  /* margin-top: 5%; */
  /* margin-bottom: 20%; */
}
.figma2001-partners {
  /* margin-left: -43%; */
}
.head-text {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.para-text {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.alert-right1-partners {
  margin-top: 28%;
}
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-container {
  position: relative;
}

.cosmos-logo-style {
  position: absolute;
  top: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cosmos {
  border-radius: 16px;
  width: 100%;
  /* height: 70px; */
  flex-shrink: 0;
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.infra-btn {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(45deg, #2f50da 0%, #a562f7 100%);
  margin-top: 5%;
  /* margin-left: 3%;
  margin-right: 3%; */
}
.infra-btn:hover {
  background: linear-gradient(45deg, #a562f7 0%, #3b4e9a 100%);
}

/* @keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.dot-animationflex {
  animation: scale 5s  ease-in infinite; 
}
.dot1 {
  animation-delay: 3s;
}
.dot2 {
  animation-delay: 1s;
}
.dot3 {
  animation-delay: 1.5s;
}
.dot4 {
  animation-delay: 2s;
} */

.dot-animationflex {
  animation: scaleAndOpacityChange 5s ease-in-out infinite;
}

@keyframes scaleAndOpacityChange {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.6;
  }
}

.dot1,
.dot2,
.dot3,
.dot4,
.dot5 {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.5s;
}

.dot3 {
  animation-delay: 1s;
}

.dot4 {
  animation-delay: 1.5s;
}

.dot5 {
  animation-delay: 2s;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.main-delegate {
  width: 100%;
  /* margin-top: 2%;
  margin-bottom: 2%; */
  /* padding: 20px 0px; */
  @apply px-6 lg:px-0  lg:pb-20 lg:pt-16;
}
.main22-delegate {
  /* width: 90%; */
  margin: auto;
}

.subLeftDevelop1-delegate h1 {
  opacity: 0.8;
  font-family: "Libre Franklin";

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: 0.6px;
  text-align: center;
  color: white;
  justify-content: center;
  align-self: stretch;
  margin-bottom: 0.5%;
}
.delegate-text-delegate {
  align-self: stretch;
  opacity: 0.8;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-align: center;
  color: white;
  margin-bottom: 3%;
}
.partners-logos {
  height: 100px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  opacity: 0.8;
}
.partners-logo-1 {
  height: 100px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  opacity: 0.8;
  margin-bottom: 3%;
}
.partners-logo-2 {
  height: 100px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  opacity: 0.8;
  margin-bottom: 2%;
}
.bottom-text {
  opacity: 0.8;
  font-family: "Libre Franklin";
  /* font-size: 20px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.4; */
  letter-spacing: 0.6px;
  text-align: center;
  color: white;
  margin-bottom: 4%;
}
.alert2001 {
  text-align: start;
  background: #1e0b1f;
  /* margin-bottom: 30%; */
}
.alert2001:hover {
  background: #372752;
}
.slick-slide > div {
  margin: 0 20px;
}
.Jack-Zampolin {
  opacity: 0.8;
  background-image: linear-gradient(to bottom, #2f50da 0%, #a562f7 100%);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.71; */
  letter-spacing: 0.56px;

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Jackk_1234 {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 18px; */
  letter-spacing: 1.2px;
}
.twitter-text {
  color: rgb(225, 225, 225);
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.6px;
}
.date-text {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 1.2px;
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.fade-in-cards {
  opacity: 0;
  animation: fadeInAnimation 3s ease forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-cards {
  opacity: 1;
  animation: fadeOutAnimation 3s ease forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .partners-logo-1,
.partners-logo-2 {
  display: flex;
  overflow: hidden;
  animation: moveLeftToRight 15s linear; 
}

.partners-logo-1 img,
.partners-logo-2 img {
  margin-right: 20px; 
} */

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.cardsanimation {
  animation: fade 10s infinite;
}
.logo-bgrnd {
  border-radius: 16px;
  /* background: rgba(255, 255, 255, 0.06); */

  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-animation {
  animation: slideIn 0.5s ease forwards;
}

/* .alert-animation {
  animation: scaleIn 3s ease-in-out infinite; 
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8); 
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} */

.center-buttons {
  /* text-align: center; */
  /* margin-top: 20px;  */
}

.center-buttons button {
  margin: 100px 10px;
}
.alert-card {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.fade-in {
  opacity: 0.8;
}

.fade-out {
  opacity: 0.8;
}

.alert-card:hover {
  transform: rotateY(180deg);
  visibility: none;
  background: #372752;
  border-radius: 12px;
}

.front {
  z-index: 100;
}

.front:hover {
  opacity: 0;
  z-index: -100;
}

.back {
  display: none;
  color: white;
}

.front:hover + .back {
  display: block;
  transform: rotateY(180deg);
}

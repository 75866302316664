@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Space Grotesk", "sans-serif"; */
  background: linear-gradient(
    91deg,
    #280a33 0.63%,
    #000 49.91%,
    #000 62.74%,
    #2d2753 99.19%
  );
}

* {
  scroll-behavior: smooth;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@layer components {
  .nav {
    @apply flex justify-between items-start w-full  lg:px-20;
  }
  .nav__logo {
    @apply w-[145px] h-[32px] lg:w-[180px] lg:h-[45px] shrink-0 px-4 lg:px-0;
  }
  .menu-list {
    @apply flex justify-end items-start gap-12  cursor-pointer;
  }
  .get-touch-btn {
    @apply flex justify-center items-center gap-2.5 h-10  px-6 py-2  border-solid  rounded-xl;
    background: linear-gradient(45deg, #2f50da 0%, #a562f7 100%);
  }
  .nav-text {
    @apply text-[rgb(225,225,225)] text-center text-sm not-italic font-normal leading-9;
    font-family: "Libre Franklin";
  }
  .badge {
    @apply flex justify-center items-center gap-2.5 h-10  px-6 py-2  border-solid rounded-t-lg;
    background: linear-gradient(45deg, #2f50da 0%, #a562f7 100%);
  }
  .navbar-fixed {
    @apply absolute top-0 w-full;
  }
  .background-nav {
    @apply backdrop-blur-[6px];
    background: linear-gradient(
        180deg,
        rgba(165, 98, 247, 0) 0%,
        rgba(47, 80, 218, 0) 100%
      ),
      rgba(255, 255, 255, 0.08);
  }
  .get-touch-btn:hover {
    background: linear-gradient(45deg, #a562f7 0%, #3b4e9a 100%);
  }
  .mobile-list {
    @apply flex flex-col justify-between items-center px-0 py-6 w-full top-0 absolute;
    background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  }

  /* footer */
  .footer {
    @apply flex flex-col gap-10 pt-10 pb-10 lg:px-20 lg:pt-24 lg:pb-24 sm:px-6 xs:px-6 w-full;
  }
  .social-icon {
    @apply w-[25px] h-[25px];
  }
  .divider-line-footer {
    @apply h-0.5  self-stretch opacity-20;
    background: rgba(255, 255, 255, 0.5);
  }
  .contact__socials {
    @apply w-10 h-10 shrink-0 rounded-lg;
    background: rgba(255, 255, 255, 0.08);
  }
  .contact__socials__popup {
    @apply w-10 h-10 shrink-0 rounded-lg;
    background: rgba(255, 255, 255, 0.201);
  }
  .contact__socials__popup:hover {
    background: #201334;
  }
  .contact__socials__icon {
    @apply hover:scale-[1.1] hover:opacity-95 transition-all ease-in delay-75 transform;
  }
  .contact__socials:hover {
    background: #201334;
  }
  .contact__socials-bg {
    @apply w-10 h-9 shrink-0 rounded-lg;
    background: rgba(255, 255, 255, 0.08);
  }
  .fotter-text {
    @apply text-[rgb(225,225,225)] text-center not-italic font-normal leading-9;
    font-family: "Libre Franklin";
    /* color: rgb(225, 225, 225); */
  }

  /* Landing */
  .background-layer {
    @apply backdrop-blur-md rounded-2xl;
    background: rgba(1, 1, 1, 0.025);
  }
  .develop-alert-background {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  /* Infrastructure */
  .infra {
    @apply text-start rounded-2xl w-full;
    background: linear-gradient(
      86deg,
      rgba(255, 255, 255, 0.06) 0.03%,
      rgba(255, 255, 255, 0) 101.65%
    );
  }
  .infra-bg {
    background: linear-gradient(
      86deg,
      rgba(255, 255, 255, 0.06) 0.03%,
      rgba(255, 255, 255, 0) 101.65%
    );
    @apply lg:h-[235px];
  }
  .infra-left {
    @apply text-start rounded-[0px_16px_16px_0px] w-[80%];
    background: linear-gradient(
      237deg,
      rgba(255, 255, 255, 0.16) 0.04%,
      rgba(186, 88, 88, 0) 99.96%
    );
  }

  .infra-right-parent {
    @apply flex justify-end;
  }

  .infra-right {
    @apply text-start rounded-[16px_0px_0px_16px] w-[80%];
    background: linear-gradient(
      237deg,
      rgba(186, 88, 88, 0) 0.04%,
      rgba(255, 255, 255, 0.16) 99.96%
    );
  }
  .left-head {
    @apply text-start w-[70%] rounded-[0px_16px_16px_0px];
    background: linear-gradient(
      237deg,
      rgba(255, 255, 255, 0.16) 0.04%,
      rgba(186, 88, 88, 0) 99.96%
    );
  }
  .right-head {
    @apply justify-end w-[70%] rounded-[16px_0px_0px_16px];
    background: linear-gradient(
      237deg,
      rgba(186, 88, 88, 0) 0.04%,
      rgba(255, 255, 255, 0.16) 99.96%
    );
  }
  /* Get in Touch */
  .getintouch-grid {
    @apply flex flex-col  shrink-0 py-10 pr-10 w-[800px];
    background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  }
  .getintouch-v-line {
    @apply h-0.5 self-stretch;
    background: rgba(255, 255, 255, 0.2);
  }
  /* ContactPopup */
  .contact-grid {
    background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  }
}

.infrastructure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  @apply px-6 pb-6 pt-10 lg:px-0 lg:py-20;
}
.infrasturcture-main-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}
.infrastructure-services {
  background: linear-gradient(180deg, #2f50da 0%, #a562f7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
}
.infrastructure-services-h1 {
  color: rgb(225,225,225);
  text-align: center;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  justify-content: center;
}
.bottom-infrastructure {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 67px;
}
.alert3001 {
  text-align: start;
  margin-bottom: 30%;
  border-radius: 0px 16px 16px 0px;
  background: linear-gradient(
    257deg,
    rgba(255, 255, 255, 0.06) 0.04%,
    rgba(186, 88, 88, 0) 99.96%
  );
}
.alert3002 {
  text-align: start;
  border-radius: 0px 16px 16px 0px;
  background: linear-gradient(
    250deg,
    rgba(255, 255, 255, 0.06) 0.02%,
    rgba(255, 255, 255, 0) 100%
  );
}

.alert3003-mobile {
  border-radius: 0px 16px 16px 0px;
  background: linear-gradient(
    237deg,
    rgba(255, 255, 255, 0.16) 0.04%,
    rgba(186, 88, 88, 0) 99.96%
  );
}
.alert3003:hover {
  background: #372752;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  flex: 1 0 0;
}

.infrastructure-main-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.infrastructure-text {
  color: rgb(225,225,225);
  text-align: center;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.6px;
}
.infrastructure-left {
  display: flex;
  position: relative;
  justify-content: flex-start;
  top: 0;
  gap: 64px;
}
.alert-right1-infra {
  width: 100%;
  margin: 0;
}
.alert-right2-infra {
  /* margin-top: 20%; */
}

.cosmos-infra {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.right-infra {
  /* margin-right: 5%; */
  margin-top: 2%;
  width: 100%;
}
.polygen {
  border-radius: 16px;
  background: #9757da;
  width: 177.333px;
  height: 80px;
}
.osmosis {
  border-radius: 16px;
  background: #a289bf;
  width: 177.333px;
  height: 80px;
}
.akash {
  border-radius: 16px;
  background: #851c12;
  width: 177.333px;
  height: 80px;
}
.passage {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.2);
  width: 177.333px;
  height: 80px;
}
.oasis {
  border-radius: 16px;
  background: #00c6eb;
  width: 177.333px;
  height: 80px;
}
.stargaze {
  border-radius: 16px;
  background: #c8dc7c;
  width: 177.333px;
  height: 80px;
}
.regen {
  border-radius: 16px;
  background: #9bd5af;
  width: 177.333px;
  height: 80px;
}
.umee {
  border-radius: 16px;
  background: #e0b0ff;
  width: 177.333px;
  height: 80px;
}
.evmos {
  border-radius: 16px;
  background: #f1705b;
  width: 177.333px;
  height: 80px;
}
.agoric {
  border-radius: 16px;
  background: #7d1729;
  width: 177.333px;
  height: 80px;
}
.infra-icons-text {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
}
.relayer-dot {
  width: 40px;
  height: 40px;
  background: #9474f6;
  border-radius: 50%;
}
.relayer-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.advisory-dot {
  width: 40px;
  height: 40px;
  background: #84e0c2;
  border-radius: 50%;
}
.advisory-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.validator-dot {
  width: 40px;
  height: 40px;
  background: #e0b0ff;
  border-radius: 50%;
}
.validator-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.testing-dot {
  width: 40px;
  height: 40px;
  background: #f07576;
  border-radius: 50%;
}
.testing-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.genesis-dot {
  width: 40px;
  height: 40px;
  background: #83a9f5;
  border-radius: 50%;
}
.genesis-dot img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.white-label {
  width: 40px;
  height: 40px;
  background: #e19b49;
  border-radius: 50%;
}
.white-label img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
.infra-text-para {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.icons-infra {
  margin-bottom: 10%;
}

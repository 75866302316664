.resolute {
  width: 100%;
  display: flex;
}
.mainDevelop-resolute {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.text {
  width: 40%;
  margin-bottom: 5%;
  margin-top: 5%;
}
.leftDevelop-resolute {
  width: 100%;
  max-width: 1280px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 30;
}
.RightDevelop-resolute {
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}
.text-style-1 {
  display: flex;
  text-align: start;
  justify-content: start;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 100% */
  /* background: linear-gradient(180deg, #fff 0%, #a562f7 100%); */
  background: rgb(225, 225, 225);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-frame {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 171.429% */
  width: 90%;
}
.text-frame p {
  display: flex;
  justify-content: start;
  text-align: start;
}
.background-video {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 30%;
}
.mobile-Resolute {
  width: 100%;
  max-width: 1280px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 30;
}
.resolute-h1 {
  color: rgb(225,225,225);
  text-align: center;
  font-family: "Libre Franklin";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.RightDevelop-delegate {
  width: 60%;
  overflow: hidden;
  position: relative;
}
.myVideop {
  width: 100%;
  object-fit: cover;
  object-position: left;
  @apply md:h-[100%];
}
.myVideopsmall {
  width: 110%;
  object-fit: cover;
  object-position: left;
  transform: translateX(-10%);
}
.video-control-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.myVideores {
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 10;
  opacity: 30%;
}
.left-bg {
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  overflow: hidden;
  /* width: 45%; */
  /* margin-right: 5%; */
}
.resolute-video {
  left: 50%;
  /* transform: translateX(50%); */
  position: absolute;
  z-index: 40;
  height: 90vh;
  width: 70vw;
  /* background-color: red; */
  /* top: 50%;
  transform: translate(-50%, -50%);
   */
}
.key-features-text {
  color: rgb(225,225,225);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}
.key-features-bgrnd {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
